<template>
  <v-container
    fluid
    tag="section"
  >
    <h1>Gemeldete Turner für {{ teamname }}:</h1>
    <v-card flat>
      <v-data-table
        :items="teamathletes"
        :headers="[
          { text: 'Nachname', value: 'familyName' },
          { text: 'Vorname', value: 'givenName' },
          { text: 'DTB-ID', value: 'dtbid', sortable: false },
          { text: 'Startmarke für', value: 'turnportal.club' },
          { text: 'Gültig ab', value: 'turnportal.validFrom' },
          { text: 'Gültig bis', value: 'turnportal.validUntil' },
          { text: 'Gesperrt', value: 'turnportal.banned' },
          { text: 'Letzte Überprüfung', value: 'turnportal.lastCheck' },
          { text: `Geräte in DTL ${vorjahr}*`, value: 'dtlgeraete', align: 'center', sortable: false }
        ]"
        sort-by="familyName"
        :items-per-page="-1"
      >
        <template #item.turnportal.validFrom="{item}">
          {{ item.turnportal.validFrom | date }}
        </template>
        <template #item.turnportal.validUntil="{item}">
          {{ item.turnportal.validUntil | date }}
        </template>
        <template #item.turnportal.banned="{item}">
          {{ item.turnportal.banned ? 'JA!' : 'nein' }}
        </template>
        <template #item.turnportal.lastCheck="{item}">
          {{ item.turnportal.lastCheck | dateformat }}
        </template>
        <template #item.dtlgeraete="{item}">
          <div
            :style="{ background: item.dtlgeraete === null ? '#fc9895' : (item.dtlauthorized ? '#a2fca5' : '#fad26b') , 'border-radius':'4px' }">
            <base-edit-dialog
              :value="item.dtlgeraete === null ? '' : item.dtlgeraete"
              :id="item._id"
              label="Geräte in DTL"
              @input="({ value, id }) => savedtl(id, value)"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
    <p><i>* bitte geben Sie an, wie viele Geräteeinsätze die Turner in der DTL in der Vorsaison hatten.</i> Hatte ein Turner einen Einsatz in der DTL, so muss dieser zuerst vom Ligabeauftragten freigegeben werden, das Feld ist dann orange.</p>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  type
  ... on StbM2021League {
    parent {
      _id
      ... on StbM2021 {
        name
      }
    }
    teams {
      team {
        _id name
      }
      athletes {
        person {
          _id givenName familyName
          identifiers(name: "dtbid") { value }
          turnportal(type: "415167291f9b4931aab76622974ccb96") { validFrom validUntil lastCheck club notes banned fixed }
        }
        dtlgeraete
        dtlauthorized
      }
    }
  }
`

export default {
  name: 'turner',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: []
  }),

  computed: {
    vorjahr () {
      return parseInt(this.EventFind?.[0]?.parent?.name?.match(/[0-9]{4}/)?.[0] || '1') - 1
    },
    league () {
      return this.EventFind?.find(e => !!e.teams?.find(t => t.team._id === this.team))
    },
    teamname () {
      return this.league?.teams?.find(t => t.team._id === this.team)?.team?.name
    },
    teamathletes () {
      return this.league?.teams?.find(t => t.team._id === this.team)?.athletes?.map(a => ({
        ...a.person,
        dtbid: a.person?.identifiers?.[0]?.value,
        turnportal: a.person?.turnportal?.[0],
        identifiers: undefined,
        dtlgeraete: a.dtlgeraete,
        dtlauthorized: a.dtlauthorized
      }))
    }
  },

  methods: {
    opendtl (person) {
      this.dtlgeraete.open = true
      this.dtlgeraete.athlete = person
      this.dtlgeraete.geraete = 0
    },
    savedtl (id, value) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!, $dtlgeraete: Int) {
            StbM2021LigaTeamUpdateAthleteUpdateDtlgeraete(id: $id, team: $team, person: $person, dtlgeraete: $dtlgeraete) { ${query} }
          }`,
        variables: {
          id: this.league._id,
          team: this.team,
          person: id,
          dtlgeraete: parseInt(value)
        }
      })
    }
  },

  apollo: {
    EventFind: {
      query: gql`
        query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
      `,
      variables () {
        return {
          parent: this.id
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
